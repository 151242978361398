import { memo, useCallback, lazy } from 'react';
import { cn } from '@divlab/divanui';
import { IconBasket, IconFavorites, IconUser, IconPhone, IconMap } from '@divlab/divanui/icons';

import { useRequest } from '@Contexts/Request';
import * as Profile from '@Queries/Profile';
import * as Cart from '@Queries/Cart';
import * as Compare from '@Queries/Compare';
import * as ModalWindows from '@Stores/ModalWindows';
import Suspense from '@Components/Suspense';
import { useFeatureFlags } from '@Contexts/FeatureFlags';
import useOnClickOutside from '@Hooks/useOnClickOutside';
import useMedias from '@Hooks/useMedias';
import { useDeps } from '@Contexts/DI';
import Link from '@Navigation/Link';
import useRouteMatchers from '@Navigation/useRouteMatchers';
import Phone from '@Layouts/elems/Phone';
import styles from './UserMenu.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface UserMenuProp extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const AuthPopup = lazy(() => import('@Layouts/elems/AuthPopup'));
const ProfilePopup = lazy(() => import('@Layouts/elems/ProfilePopup'));

const handleMouseEnterUser = () => Profile.updateWelcomePopup({ hovered: true });

const handleMouseLeaveUser = () => Profile.updateWelcomePopup({ hovered: false });

const handleClickOutside = () => {
  Profile.updateWelcomePopup({ clicked: false, hovered: false });
};

const UserMenu: FC<UserMenuProp> = (props) => {
  const { className, ...restProps } = props;
  const { country } = useRequest();
  const { isOrderCheck } = useRouteMatchers();
  const shortCart = Cart.useCartShortInfo();
  const profile = Profile.useProfile();
  const { compareCount } = Compare.useCompare();
  const featureFlags = useFeatureFlags();
  const { isMobile, isMobileM } = useMedias();
  const { hovered, clicked } = Profile.useWelcomePopup();
  const { analytics } = useDeps();
  const visibleProfilePopup = (!isMobile && hovered) || clicked;
  const isRus = country === 'RUS';

  const cartCount = shortCart.data?.count || 0;

  const handleClickBasket = useCallback(() => {
    analytics.dispatchEvent('cart.clickIcon');
  }, [analytics]);

  const handleClickUser = useCallback(() => {
    Profile.updateWelcomePopup({ hovered: true, clicked: !clicked });
  }, [clicked]);

  const handleClickPhone = useCallback(
    (e: MouseEvent) => {
      if (isRus) return;

      e.preventDefault();
      ModalWindows.open('Feedback');
    },
    [isRus],
  );

  const handleClickRegion = useCallback(() => {
    analytics.dispatchEvent('region.selector.open', { label: 'header' });
    ModalWindows.open('RegionSelector');
  }, [analytics]);

  const refPopup = useOnClickOutside<HTMLDivElement>(handleClickOutside);

  return (
    <div {...restProps} className={cn(styles.list, className)}>
      {!isMobileM && featureFlags.personalAreaAvailable && (
        <div
          aria-label='Личный кабинет'
          data-testid={`${profile.data !== null ? '' : 'un'}authorization-desktop`}
          role='button'
          className={styles.userMenuItem}
          ref={refPopup}
          onMouseEnter={handleMouseEnterUser}
          onMouseLeave={handleMouseLeaveUser}
          onClick={handleClickUser}
        >
          <IconUser className={styles.icon} full={!!profile.data} />

          {visibleProfilePopup && (
            <Suspense fallback={null}>
              <div className={styles.popup}>
                {profile.data ? (
                  <ProfilePopup
                    profile={profile.data}
                    full={clicked}
                    onClickItem={handleClickOutside}
                  />
                ) : (
                  <AuthPopup />
                )}
              </div>
            </Suspense>
          )}
        </div>
      )}

      {isMobileM ? (
        <div className={styles.userMenuItem} onClick={handleClickRegion}>
          <IconMap className={styles.icon} />
        </div>
      ) : (
        <Link
          className={cn(styles.userMenuItem, styles.favorites)}
          to='/favorites'
          aria-label='Избранное'
          role='button'
          data-testid={isMobileM ? 'favorites-sum-mobile' : 'favorites-sum-desktop'}
        >
          <IconFavorites className={styles.icon} full={!!compareCount} />
          {!!compareCount && <span className={styles.count}>{compareCount}</span>}
        </Link>
      )}

      {isMobileM ? (
        <Phone className={styles.userMenuItem} onClick={handleClickPhone}>
          <IconPhone className={styles.icon} />
        </Phone>
      ) : (
        <Link
          to='/order/check'
          aria-label='Корзина'
          role='button'
          className={styles.userMenuItem}
          onClick={handleClickBasket}
        >
          <IconBasket className={styles.icon} full={!!cartCount} active={isOrderCheck} />
          {!!cartCount && <span className={styles.count}>{cartCount}</span>}
        </Link>
      )}
    </div>
  );
};

export default memo(UserMenu);
