import { StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';
import { RouterProvider, matchRoutes, createBrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider, hydrate as hydrateState, QueryClient } from '@tanstack/react-query';

import { Manager } from '@Events/Manager';
import { CompareListener } from '@Events/listeners/compare-listener';
import { WebPushListener } from '@Events/listeners/web-push-listener';
import { AnalyticsCartLoadListener } from '@Events/listeners/analytics-cart-load-listener';
import { AppConfigProvider } from '@Contexts/AppConfig';
import { RequestProvider } from '@Contexts/Request';
import { FeatureFlagsProvider } from '@Contexts/FeatureFlags';
import { DIProvider, DIContainer } from '@Contexts/DI';
import { DummyCache } from '@Infrastructure/details/cache/DummyCache';
import { SentryBrowserLogger } from '@Infrastructure/details/loggers/SentryBrowserLogger';
import { CalltouchService } from '@Infrastructure/details/telephony/CalltouchService';
import { AnalyticsEventDispatcher } from '@Infrastructure/details/analytics/AnalyticsEventDispatcher';
import { createRoutes } from '@Navigation/routes';
import PageError from '@Pages/PageError';
import CabinetApi from '@Api/Cabinet';
import ErrorBoundary from '@Components/ErrorBoundary';

import type { Query } from '@Types/Base';

loadableReady(async () => {
  const root = document.getElementById('root');
  const state = window.__SERVER_STATE__;
  const appConfig = window.__CONFIG__;
  const featureFlags = window.__FEATURE_FLAGS__;

  const loggers = {
    development: console,
    staging: console,
    production: new SentryBrowserLogger(appConfig.sentry),
  };
  const logger = loggers[appConfig.environment];

  const country = appConfig.country;
  const query: Query = {
    device: appConfig.device,
    origin: appConfig.origin,
    proxyOrigin: appConfig.proxyOrigin,
    region: appConfig.region,
    regionId: appConfig.regionId,
    prevRegion: appConfig.prevRegion,
    secondaryRegion: appConfig.secondaryRegion,
    allRegions: appConfig.allRegions,
    mainRegion: appConfig.mainRegion,
    language: appConfig.language,
    supportedLanguages: appConfig.supportedLanguages,
    defaultLanguage: appConfig.defaultLanguage,
    pilotRegions: appConfig.pilotRegions,
    status: { code: appConfig.status.code || 200, message: appConfig.status.message },
    queryClient: new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          retryOnMount: false,
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
        },
      },
    }),
    country: appConfig.country,
    traits: { country: appConfig.country },
    regionPopup: '',
    isRus: country === 'RUS',
    isBlr: country === 'BLR',
    isKaz: country === 'KAZ',
    isUzb: country === 'UZB',
  };

  const isCriticalError = query.status.code >= 500;

  const routes = createRoutes(query);

  // Determine if any of the initial routes are lazy
  const lazyMatches = matchRoutes(routes, window.location)?.filter((m) => m.route.lazy);

  // Load the lazy matches and update the routes before creating your router
  // so we can hydrate the SSR-rendered content synchronously
  if (lazyMatches && lazyMatches?.length > 0) {
    await Promise.all(
      lazyMatches.map(async (m) => {
        const routeModule = await m.route.lazy();

        Object.assign(m.route, {
          ...routeModule,
          lazy: undefined,
        });
      }),
    );
  }

  const analytics = new AnalyticsEventDispatcher({
    analyticsUrl: appConfig.analyticsUrl,
    logger,
  });

  const eventManager = new Manager({
    logger,
    listeners: [
      new CompareListener({
        queryClient: query.queryClient,
        analytics,
      }),
      new WebPushListener({
        country: query.country,
      }),
      new AnalyticsCartLoadListener({ analytics }),
    ],
  });

  DIContainer.initDeps({
    eventManager: eventManager,
    logger: logger,
    analytics: analytics,
    cabinetApi: new CabinetApi({ country: query.country }),
    telephony: appConfig.calltouchModId
      ? new CalltouchService({
          calltouchUrl: appConfig.calltouchUrl,
          calltouchClientUrl: appConfig.calltouchClientUrl,
          logger,
          modId: appConfig.calltouchModId,
          country: query.country,
        })
      : null,
    cache: new DummyCache(),
    featureFlagManager: null,
    regionManager: null,
    deviceDetector: null,
  });

  const router = createBrowserRouter(routes);
  const tree = (
    <ErrorBoundary>
      <StrictMode>
        <DIProvider value={DIContainer.getDeps()}>
          <HelmetProvider>
            <AppConfigProvider value={appConfig}>
              <FeatureFlagsProvider value={featureFlags}>
                <RequestProvider value={query}>
                  <QueryClientProvider client={query.queryClient}>
                    {isCriticalError ? (
                      <PageError message='Critical error 500' />
                    ) : (
                      <RouterProvider
                        router={router}
                        fallbackElement={<PageError message=' Router fallback element' />}
                        future={{ v7_startTransition: true }}
                      />
                    )}
                  </QueryClientProvider>
                </RequestProvider>
              </FeatureFlagsProvider>
            </AppConfigProvider>
          </HelmetProvider>
        </DIProvider>
      </StrictMode>
    </ErrorBoundary>
  );

  hydrateState(query.queryClient, state);
  hydrateRoot(root, tree);
});

if (module.hot) {
  module.hot.accept();
}
