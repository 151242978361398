import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';
import generateProductHash from '@Utils/generateProductHash';

import type { CartModalInputProduct, UpdatedCartInfo } from '@Types/Cart';

export interface Params {
  products: CartModalInputProduct[];
  commercialGuid?: string;
}

export interface Result {
  result: 'success' | 'error';
  data: UpdatedCartInfo;
}

async function put({ products, commercialGuid }: Params) {
  const url = '/cabinet/cart/v2/put';

  const hashedProducts = products.map((product) => {
    product.hash = generateProductHash(product);
    return product;
  });
  const body = { products: hashedProducts, ...(commercialGuid && { commercialGuid }) };
  const res = await Api.queryProxi<Result>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  if (!res.data) throw new BackendError(url, res);

  return res.data;
}

export default put;
