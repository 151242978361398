import { memo, useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { cn } from '@divlab/divanui';

import * as ModalWindows from '@Stores/ModalWindows';
import * as HeaderStore from '@Stores/Header';
import * as SearchStore from '@Stores/Search';
import * as Meta from '@Queries/Meta';
import { useComponentStorage } from '@Stores/ComponentStorage';
import useMedias from '@Hooks/useMedias';
import useRenderType from '@Hooks/useRenderType';
import useScrollPosition from '@Hooks/useScrollPosition';
import { useLayout } from '@Queries/useLayout';
import useInfoStripe from '@Queries/InfoStripe/useInfoStripe';
import useNavigation from '@Navigation/useNavigation';
import ToplineMenu from './elems/ToplineMenuContainer';
import DropDown from './elems/DropDown';
import HeaderMobile from './elems/HeaderMobile';
import HeaderTablet from './elems/HeaderTablet';
import HeaderDesktop from './elems/HeaderDesktop';
import SSRMenuLinks from './elems/SSRMenuLinks';
import styles from './Header.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { MainMenuItem } from '@Types/Layout';

export interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const Header: FC<HeaderProps> = () => {
  const { pathname, hash } = useNavigation();
  const components = useComponentStorage();
  const { isOnlyMobile, isMobileM, isDesktop, isOnlyDesktop, isMobileXXS, isMobile } = useMedias();
  const { isSSR } = useRenderType();
  const { fixed, pinned, isOpenedMenu } = HeaderStore.useMenu();
  const { secondLevel } = HeaderStore.useSelectedLevels();
  const queryInfoStripe = useInfoStripe();
  const infoStripe = queryInfoStripe?.data;
  const { toplineHeight } = HeaderStore.useSizes();
  const [animable, setAnimable] = useState(false);
  const [withShadow, setWithShadow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const toplineRef = useRef<HTMLDivElement>(null);
  const { data: regionPopup } = Meta.useRegionQuestion();
  const layout = useLayout();

  const needTopline =
    (!!infoStripe || (isOnlyDesktop && layout.data?.topLine?.length > 0)) && !infoStripe?.banner;
  const needTopLineBanner = infoStripe?.banner && isOnlyDesktop;

  const compressed = useMemo(() => {
    return (pinned || fixed) && withShadow;
  }, [fixed, pinned, withShadow]);

  const compensation = useMemo(() => {
    let height = 168;
    if (isDesktop) {
      height = compressed ? 51 : 75;
    }

    if (isMobileM) {
      height = (compressed ? 56 : 65) + toplineHeight;
    }

    if (isMobile) {
      height = 65 + toplineHeight;
    }

    if (isMobileXXS) {
      height = 60 + toplineHeight;
    }

    return height;
  }, [isDesktop, isMobileM, isMobile, isMobileXXS, compressed, toplineHeight]);

  const topLine = useMemo(() => {
    const left: MainMenuItem[] = [];
    const right: MainMenuItem[] = [];

    layout.data.topLine.forEach((item) => {
      if (item.parameters.position === 'left') {
        left.push(item);
      } else {
        right.push(item);
      }
    });

    return {
      left,
      right,
    };
  }, [layout.data.topLine]);

  const handleClickLink = useCallback(() => {
    HeaderStore.closeMenu();
  }, []);

  // Изменяем модификаторы в зависимости от скрола
  useScrollPosition(({ current, previous }) => {
    if (document.documentElement.scrollTop < 3) {
      HeaderStore.unfix();
      HeaderStore.unpin();
      setWithShadow(false);
      return;
    }

    if (isOnlyMobile) {
      const { clientHeight, scrollHeight } = document.documentElement;
      const isUp = previous.y > current.y;
      const newPinned = pinned && isUp ? pinned : isUp && current.y > ref.current?.clientHeight;
      const newFixed = fixed && isUp ? fixed : current.y - 2 > ref.current?.clientHeight;

      if (clientHeight === scrollHeight) return;
      if (previous.y === current.y) return;
      if (newPinned === pinned && newFixed === fixed) return;

      HeaderStore.fix(newFixed);
      HeaderStore.pin(newPinned);
      setWithShadow(newPinned && current.y > 5);
      setTimeout(() => setAnimable(newFixed));
    } else {
      HeaderStore.fix(current.y > 0);
    }
  });

  // Рассчитываем высоту анонсной строки
  useEffect(() => {
    function calculate() {
      setTimeout(() => {
        HeaderStore.setSizes({ toplineHeight: 0 });

        if (!needTopline && !needTopLineBanner) return;
        if (!toplineRef.current) return;

        const { height } = toplineRef.current.getBoundingClientRect();

        HeaderStore.setSizes({ toplineHeight: height });
      });
    }

    calculate();
    window.addEventListener('resize', calculate);

    return () => {
      window.removeEventListener('resize', calculate);
    };
  }, [needTopline, needTopLineBanner]);

  useEffect(() => {
    HeaderStore.unfix();
    HeaderStore.unpin();
    if (!pathname.includes('/search')) SearchStore.setDefaultSearchResult();
  }, [pathname]);

  useEffect(() => {
    if (!regionPopup || isOnlyDesktop) return;

    // Из-за очередности очистки очереди попапов необходимо сделать задержку
    const timer = setTimeout(() => {
      ModalWindows.open('RegionQuestion', { data: regionPopup }, { needToReplace: false });
    }, 1000);
    return () => clearTimeout(timer);
  }, [isOnlyDesktop, hash, regionPopup]);

  if (!layout.isSuccess || !layout.data) return null;

  return (
    <>
      {isSSR && <SSRMenuLinks />}

      <header
        className={cn(styles.header, {
          [styles.fixed]: fixed,
          [styles.pinned]: pinned,
          [styles.animable]: animable,
        })}
        ref={ref}
      >
        <ToplineMenu
          className={styles.topline}
          items={topLine}
          ref={toplineRef}
          needTopline={needTopline}
          needTopLineBanner={needTopLineBanner}
        />

        {(() => {
          if (isMobileM) {
            return <HeaderMobile withShadow={withShadow} />;
          }

          if (isDesktop) {
            return <HeaderTablet withShadow={withShadow} />;
          }

          return <HeaderDesktop special={layout.data.special} />;
        })()}
        {isDesktop && components?.filters && components.filters}
      </header>

      {isOnlyDesktop && !!secondLevel && (
        <DropDown opened={isOpenedMenu} onClickLink={handleClickLink} />
      )}

      {fixed && <div className={styles.compensation} style={{ height: `${compensation}px` }} />}
    </>
  );
};

export default memo(Header);
