import * as Api from '@Api/index';
import { BackendError, IncorrectBackendResponseError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { RegionQuestionData } from '@Types/Region';

async function getRegionQuestion() {
  const { headers } = Api.getRequest();
  const queryUrl = `/region-popup`;

  const res = await fetch(queryUrl, { headers, credentials: 'include' });

  if (res.status !== 200) return null;

  let json: ApiResponse<RegionQuestionData> = null;

  try {
    json = await res.json();
  } catch (err) {
    throw new IncorrectBackendResponseError({ url: queryUrl, status: res.status });
  }

  if (!json.ok) throw new BackendError(queryUrl, res);

  return json.data;
}

export default getRegionQuestion;
