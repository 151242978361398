import { useState } from 'react';
import { cn } from '@divlab/divanui';

import { useDeps } from '@Contexts/DI';
import CopiedIcon from './CopiedIcon';
import Tooltip from './Tooltip';
import CopyIcon from './CopyIcon';
import styles from './Promocode.module.css';

import type { FC, HTMLAttributes } from 'react';

interface PromocodeProps extends HTMLAttributes<HTMLDivElement> {
  view?: 'primary' | 'secondary';
  text: string;
  background: string;
}

const Promocode: FC<PromocodeProps> = (props) => {
  const { className, view = 'primary', text, color, ...restProps } = props;
  const [copied, setCopied] = useState(false);
  const showTooltipMs = 3000;

  const tooltip = 'Промокод успешно скопирован';
  const { analytics } = useDeps();

  const handleCopy = () => {
    if (navigator?.clipboard && !copied) {
      navigator.clipboard.writeText(text);
      setCopied(true);

      analytics.dispatchEvent('landing.interact', {
        label: '',
        category: 'promocode',
        action: 'click',
      });

      const timer = setTimeout(() => setCopied(false), showTooltipMs);

      return () => clearTimeout(timer);
    }
  };

  return (
    <div className={cn(styles.wrapper, className)} {...restProps}>
      <div
        className={cn(styles.container, {
          [styles.primary]: view === 'primary',
          [styles.secondary]: view === 'secondary',
          [styles.copied]: copied,
        })}
      >
        <div className={styles.content} onClick={view === 'primary' ? handleCopy : null}>
          <div
            className={styles.promocodeWrapper}
            onClick={view === 'secondary' ? handleCopy : null}
          >
            <div style={{ color: color }}>{text}</div>
            {copied ? (
              <CopiedIcon className={styles.icon} style={{ color: '#27AE60' }} />
            ) : (
              <CopyIcon className={styles.icon} style={{ color: color }} />
            )}
          </div>
        </div>

        <Tooltip visible={copied} timerMs={showTooltipMs}>
          {tooltip}
        </Tooltip>
      </div>
    </div>
  );
};

export default Promocode;
