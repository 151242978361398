import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import * as ApiMeta from '@Api/Meta';
import { useRequest } from '@Contexts/Request';
import setCookie from '@Utils/setCookie';
import useNavigation from '@Navigation/useNavigation';

import type { UseQueryResult } from '@tanstack/react-query';
import type { AbTestGroup, AbTests, AbTestOptions } from '@Types/AbTests';

export const useAbTests = (options?: AbTestOptions): UseQueryResult<AbTests> => {
  const { search } = useNavigation();
  const { queryClient, region } = useRequest();

  // При добавлении options в AbTest необходимо добавить параметр в keys
  const keys = ['abtests', { region }];
  const result = useQuery({
    queryKey: keys,
    queryFn: () => {
      const cache = queryClient.getQueryData<AbTests>(keys);
      if (cache) return Promise.resolve(cache);

      return ApiMeta.getAbTests(search, options);
    },
    enabled: true,
    placeholderData: (previousData) => previousData,
  });

  useEffect(() => {
    if (!result.data) return;

    for (const key in result.data.groups) {
      const abTestGroup: AbTestGroup = result.data.groups[key];

      if ('cookie' in abTestGroup) {
        const cookie = abTestGroup.cookie;

        setCookie(cookie.name, cookie.value, { expires: new Date(cookie.expires * 1000) });
      }
    }
  }, [result.data]);

  return result;
};

export const useAbTest = (
  abTestName: keyof AbTests['groups'],
  options?: AbTestOptions,
): AbTestGroup => {
  const abTests = useAbTests(options);

  if (!abTests.isSuccess || !abTests.data.groups[abTestName]) return undefined;

  return abTests.data.groups[abTestName];
};

export const useAbTestGroup = (abTestName: keyof AbTests['groups']): string => {
  const abTests = useAbTests();

  if (!abTests.isSuccess || !abTests.data.groups[abTestName]) return undefined;

  return abTests.data.groups[abTestName].group;
};
