import { useMutation } from '@tanstack/react-query';

import ApiCart from '@Api/Cart';
import OrderFormStore from '@Stores/OrderForm/OrderFormStore';
import transformCart from '@Queries/Cart/helpers/transformCart';
import { useRequest } from '@Contexts/Request';
import { useDeps } from '@Contexts/DI';
import useCartKeys from './useCartKeys';

import type { CartData, CartModalInputProduct } from '@Types/Cart';

export interface Variables {
  products: CartModalInputProduct[];
  options: { isRelated: boolean };
  commercialGuid?: string;
  analyticsLabel?: string;
}

export const useAddToCart = () => {
  const { queryClient } = useRequest();
  const keys = useCartKeys();
  const { analytics } = useDeps();

  return useMutation<CartData, Error, Variables>({
    mutationFn: async ({ products, options, commercialGuid }) => {
      const res = await ApiCart.put({ products, commercialGuid });

      return transformCart({ cart: res, options });
    },
    onSuccess: (cart, { products, analyticsLabel }) => {
      const paymentTypeId = products.find((product) => product.paymentTypeId)?.paymentTypeId;
      if (paymentTypeId) {
        OrderFormStore.select({ paymentType: paymentTypeId });
      }

      queryClient.setQueryData(keys, (prev: CartData) => ({ ...prev, ...cart }));
      queryClient.invalidateQueries({ queryKey: ['cartShortInfo'], refetchType: 'all' });

      analytics.dispatchEvent('cart.add', { positions: cart.newPositions });
      analytics.dispatchEvent('cart.update', { cart, addLabel: analyticsLabel });
    },
  });
};

export default useAddToCart;
